.steps {
  position: relative;
  overflow: hidden;
  height: 100%;
  background: linear-gradient(rgba(137, 215, 215, 0.8), rgba(137, 215, 215, 0.8)), url('../images/run/steps/bg.jpg') center/cover no-repeat;

  @media (min-width: 1200px) {
    height: 1914px;
    &::after {
      content: '';
      width: 1px;
      background-color: #000;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
    }
  }

  &__bg-flower-1 {
    position: absolute;
    width: 70px;
    right: -27px;
    top: 40%;
    @media (min-width: 760px) {
      display: none;
    }
    @media (min-width: 1200px) {
      display: block;
      width: 315px;
      top: 524px;
      right: -140px;
    }
  }

  &__bg-flower-2 {
    position: absolute;
    width: 68px;
    left: -20px;
    top: 22%;
    @media (min-width: 760px) {
      display: none;
    }
    @media (min-width: 1200px) {
      display: block;
      width: 183px;
      top: 939px;
      left: -44px;
    }
  }

  &__bg-flower-3 {
    position: absolute;
    width: 73px;
    right: -27px;
    bottom: 18%;
    @media (min-width: 760px) {
      display: none;
    }
    @media (min-width: 1200px) {
      display: block;
      width: 82px;
      top: 1278px;
      right: 20%;
    }
  }

  &__bg-flower-4 {
    position: absolute;
    width: 70px;
    left: -25px;
    bottom: 38%;
    @media (min-width: 760px) {
      display: none;
    }
    @media (min-width: 1200px) {
      display: block;
      width: 110px;
      bottom: 111px;
      left: 30%;
    }
  }

  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 19px;
    line-height: 120%;
    color: $dark-text-color;
    text-transform: uppercase;
    @media (min-width: 760px) {
      font-size: 40px;
    }
  }

  &__text-mob {
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    color: $dark-text-color;

    margin-bottom: 17px;
    @media (min-width: 760px) {
      display: none;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
    max-width: 450px;
    margin: 0 auto;

    @media (min-width: 760px) {
      margin-top: 50px;
      max-width: 100%;
    }

    @media (min-width: 1200px) {
      margin: 0;
      margin-top: 50px;
    }
  }

  &__item-first {
    display: none;
    @media (min-width: 760px) {
      display: block;
      width: 100%;
      height: 320px;
      border-radius: 8px;
    }
  }

  ul > &__item {
    border-radius: 17px;
    @media (min-width: 760px) {
      width: calc(50% - 15px);
    }
    @media (min-width: 1200px) {
      width: 450px;
      height: 398px;
    }
  }

  &__item-first-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: $dark-text-color;
    &:first-child {
      margin-bottom: 20px;
    }
  }

  &__item-main {
    width: 100%;
    height: 320px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 25px;
    padding: 20px;
    background: linear-gradient(360deg, #000000 45.19%, rgba(0, 0, 0, 0) 100%);

    @media (min-width: 1200px) {
      align-items: center;
      justify-content: flex-end;
      position: absolute;
    }
  }

  &__item1 {
    @media (min-width: 1200px) {
      top: 182px;
      left: 58%;
    }
  }
  &__item2 {
    @media (min-width: 1200px) {
      top: 480px;
      right: 58%;
    }
  }
  &__item3 {
    @media (min-width: 1200px) {
      top: 820px;
      left: 58%;
    }
  }
  &__item4 {
    @media (min-width: 1200px) {
      top: 1127px;
      right: 58%;
    }
  }
  &__item5 {
    @media (min-width: 1200px) {
      top: 1443px;
      left: 58%;
    }
  }

  &__item-label-mob {
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    color: $dark-text-color;
    background-color: $accent-color;
    padding: 5px;
    border-radius: 3px;

    width: fit-content;
    block-size: fit-content;

    @media (min-width: 760px) {
      font-size: 19px;
    }
    @media (min-width: 1200px) {
      display: none;
    }
  }

  &__item-label-desk {
    display: none;
    @media (min-width: 1200px) {
      display: block;
      font-weight: 600;
      font-size: 20px;

      color: $dark-text-color;
      background-color: $accent-color;
      padding: 7px 13px;
    }
  }

  &__item-label-desk1,
  &__item-label-desk3,
  &__item-label-desk5 {
    // display: inline-block;
    // width: auto;
    // height: 38px;
    // background-color: $accent-color;
    // -webkit-border-radius: 3px 4px 4px 3px;
    // -moz-border-radius: 3px 4px 4px 3px;
    // border-radius: 3px 4px 4px 3px;

    // border-left: 1px solid $accent-color;

    // /* This makes room for the triangle */
    // margin-left: 19px;

    // position: relative;

    // line-height: 38px;

    // padding: 0 10px 0 10px;
    // !-----
    position: absolute;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    top: 39px;
    left: -10px;
    border-right: 1px solid $accent-color;
  }

  &__item-label-desk1:before,
  &__item-label-desk3:before,
  &__item-label-desk5:before {
    // content: '';
    // position: absolute;
    // display: block;
    // left: -18px;
    // width: 0;
    // height: 0;
    // border-top: 19px solid transparent;
    // border-bottom: 19px solid transparent;
    // border-right: 19px solid $accent-color;
    // !-----
    content: '';
    position: absolute;
    left: -17px;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 17px solid $accent-color;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
  }

  &__item-label-desk2,
  &__item-label-desk4 {
    position: absolute;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    top: 39px;
    right: -10px;
    border-right: 1px solid $accent-color;
  }
  &__item-label-desk2:before,
  &__item-label-desk4:before {
    content: '';
    position: absolute;
    right: -18px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 17px solid $accent-color;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
  }

  &__item-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: $light-text-color;
    max-width: 85%;
    @media (min-width: 760px) {
      font-size: 16px;
    }
    @media (min-width: 1200px) {
      text-align: center;
    }
  }

  &__step-item {
    display: none;
    @media (min-width: 1200px) {
      display: block;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: $dark-text-color;
      text-align: center;
      padding: 14px 0;

      width: 120px;
      height: 50px;
      background-color: $accent-color;
      border: 2px solid $dark-text-color;
      border-radius: 8px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }
  }

  &__step-item1 {
    top: 213px;
  }
  &__step-item2 {
    top: 513px;
  }
  &__step-item3 {
    top: 848px;
  }
  &__step-item4 {
    top: 1158px;
  }
  &__step-item5 {
    top: 1476px;
  }

  //   @media (min-width: 760px) {
  //     &__content {
  //       display: grid;
  //       grid-template-columns: 1fr 1fr;
  //     }
  //   }

  //   @media (min-width: 1100px) {
  //     &::after {
  //       content: '';
  //       width: 1px;
  //       background-color: #000;
  //       height: 95%;
  //       position: absolute;
  //       top: 2%;
  //       left: 50%;
  //     }
  //   }

  //   @media (min-width: 760px) {
  //     &__content {
  //       display: flex;
  //       grid-template-columns: 1fr 1fr;
  //     }
  //     &__title {
  //       margin-bottom: 100px;
  //     }
  //   }
  // }

  // .step-block {
  //   max-width: 320px;
  //   position: relative;
  //   width: 100%;
  //   height: 273px;
  //   white-space: nowrap;
  //   z-index: 10;
  //   align-self: center;

  //   // .step-block__relative-text

  //   &__relative-text {
  //     position: absolute;
  //     text-align: center;
  //     bottom: 25px;
  //     transform: translateX(-50%);
  //     left: 50%;
  //     width: 60%;
  //     white-space: normal;
  //   }
  //   &__step-text {
  //     position: absolute;
  //     left: 0;
  //     // transform: translateX(-50%);
  //     top: 15%;
  //   }

  //   &_bg {
  //     border-radius: 17px;
  //     height: inherit;
  //   }

  //   @media (min-width: 760px) {
  //     margin-top: -100px;
  //     max-width: 466px;
  //     height: 399px;

  //     &__step-text {
  //       //   background-color: $accentColor;
  //       //   border: 1px solid $lightColor;
  //       border-radius: 8px;
  //       //   color: $lightColor;
  //       box-shadow: 0px 4px 8px 5px rgba(28, 62, 44, 0.44);
  //       top: 5%;
  //       &:hover {
  //         // background-color: $accentColor;
  //       }
  //     }

  //     &_odd {
  //       align-self: flex-start;
  //       &:after {
  //         // content: url(../assets/arrow-triangle.svg);
  //         position: absolute;
  //         top: 3%;
  //         right: -8%;
  //       }

  //       & .step-block__step-text {
  //         left: 110%;
  //       }
  //     }

  //     &_even {
  //       align-self: flex-end;
  //       &:after {
  //         // content: url(../assets/arrow-triangle.svg);
  //         transform: rotate(180deg);
  //         position: absolute;
  //         top: 3%;
  //         left: -8%;
  //       }
  //       & .step-block__step-text {
  //         left: -10%;
  //         transform: translateX(-100%);
  //       }
  //     }
  //   }
}

.price-tag {
  display: inline-block;

  width: auto;
  height: 38px;

  background-color: #333;
  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 3px 4px 4px 3px;

  border-left: 1px solid #333;

  /* This makes room for the triangle */
  margin-left: 19px;

  position: relative;

  color: white;
  line-height: 38px;

  padding: 0 10px 0 10px;
}

/* Makes the triangle */
.price-tag:before {
  content: '';
  position: absolute;
  display: block;
  left: -19px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-right: 19px solid #333;
}
