.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #005858d4;
  top: 0;

  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.8s ease 0s;
  z-index: 110;

  &__body {
    width: 100%;
    min-height: 100%; /* Fallback for browsers that do not support Custom Properties */
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 10px;
    position: absolute;
    @media (min-width: 1200px) {
      // padding: 30px;
    }
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 27px;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
    @media (min-width: 1180px) {
      right: 40px;
      top: 20px;
    }

    &_small {
      right: 15px;
      top: 15px;
    }
  }

  &__title {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 140%;

    @media (min-width: 1180px) {
      font-size: 24px;
    }
  }

  &__subtitle {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 130%;

    @media (min-width: 1180px) {
      font-size: 16px;
    }
  }
  &__notice {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 130%;

    @media (min-width: 1180px) {
      font-size: 12px;
    }
  }
  &__form {
    width: 100%;
    form {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (min-width: 1180px) {
        gap: 15px;
      }
    }
  }

  &__content {
    transition: all 0.8s ease 0s;
    opacity: 0;
    transform: perspective(600px) translate(0px, -100%) rotateX(45deg);
  }
}

.popup.open {
  opacity: 1;
  visibility: visible;
}

.popup.open .popup__content {
  transform: perspective(600px) translate(0px, 0%) rotateX(0deg);
  opacity: 1;
}

.close-icon {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  // z-index: 3;
  &:hover {
    cursor: pointer;
  }
  span {
    position: absolute;
    background-color: $accent-color;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    transform: rotate(-45deg);
  }
  &:before {
    content: '';
    background-color: $accent-color;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transform: rotate(45deg);
    top: 50%;
  }

  @media (min-width: 1180px) {
    width: 35px;
    height: 35px;
    span {
      height: 4px;
    }
    &:before {
      height: 4px;
    }
  }

  &_red {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 13px;
    height: 13px;
    span {
      background-color: red;
      height: 2px;
    }
    &:before {
      background-color: red;

      height: 2px;
    }
    &:hover {
      cursor: auto;
    }
  }

  &_small {
    width: 20px;
    height: 20px;
    span {
      height: 2px;
    }
    &:before {
      height: 2px;
    }
  }
}

.input-error {
  border: 1px solid red !important;
}
