.content-packages {
  // width: 100vw;
  // height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  background-color: #000000;
  color: $accent-color;
  padding: 70px 20px 20px 20px;
  position: relative;
  transition: all 0.8s ease 0s;
  opacity: 0;
  transform: perspective(600px) translate(0px, -100%) rotateX(45deg);

  // max-width: -webkit-fill-available;
  // height: 100%;
  @media (min-width: 760px) {
    max-width: 420px;
  }
  @media (min-width: 1160px) {
    max-width: 1440px;
  }

  &__logo {
    position: absolute;
    top: 25px;
    left: 20px;
    height: 20px;
    @media (min-width: 1160px) {
      top: 25px;
      left: 36px;
      height: 44px;
    }
  }

  &__wrap {
    display: none;
    @media (min-width: 1160px) {
      display: inline-block;
    }
  }

  &__list {
    @media (min-width: 1160px) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      flex-wrap: nowrap;
    }
  }

  &__item {
    position: relative;
    border: 1px solid $accent-color;
    padding: 27px;
    background: rgba(30, 56, 53, 0.42);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 39px;
    // max-width: 420px;
    // height: 1800px;

    @media (min-width: 1160px) {
      background: rgba(23, 23, 23, 0.42);
      margin: 15px;
      max-width: 367px;
      height: 100%;
      border-radius: 17px;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__icon-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;

    // position: absolute;
    // right: 30px;
  }

  &__icon {
    filter: drop-shadow(0px 2px 8px #89d7d7);
    width: 27px;
    height: 100%;
    @media (min-width: 760px) {
      width: 46px;
      height: 100%;
    }
  }

  &__icon-text {
    font-weight: 700;
    font-size: 8px;
    line-height: 120%;
    color: $accent-color;
    @media (min-width: 760px) {
      font-weight: 500;
      font-size: 12px;
      line-height: 123%;
    }
  }

  &__membership {
    font-weight: 700;
    font-size: 17px;
    line-height: 125%;
    color: $accent-color;
    padding-bottom: 2px;
    border-bottom: 2px solid $accent-color;
    width: fit-content;

    @media (min-width: 1160px) {
      font-weight: 600;
      font-size: 30px;
      line-height: 140%;
    }
  }

  &__price-crossed-wrap {
    position: relative;
  }

  &__price-crossed {
    position: absolute;
    width: 70px;
    top: 5px;
    left: -7px;

    @media (min-width: 1160px) {
      width: 77px;
      left: -3px;
    }
  }

  &__old-price {
    font-weight: 600;
    font-size: 22px;
    line-height: 105.5%;
    margin-bottom: 10px;
    color: $light-text-color;
    @media (min-width: 1160px) {
      font-size: 28px;
      line-height: 120%;
    }
  }

  &__price {
    font-weight: 800;
    font-size: 32px;
    line-height: 105.5%;
    color: $accent-color;
    margin-bottom: 20px;
    @media (min-width: 1160px) {
      font-size: 40px;
      line-height: 120%;
    }
  }

  &__features-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media (min-width: 1160px) {
      width: 289px;
    }
  }

  &__features-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__features-point {
    max-width: 200px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #d2d2d2;
  }

  &__features-stats {
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    text-align: end;
  }

  &__btn {
    // position: absolute;
    // bottom: 20px;
    // left: 50%;
    // transform: translateX(-50%);

    margin-top: 32px;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;

    padding: 9px 0;

    // align-items: center;
    // justify-content: center;
    text-align: center;

    border: none;
    cursor: pointer;
    background-color: $accent-color;
    color: $dark-text-color;
    text-align: center;

    width: 155px;
    height: 32px;
    border-radius: 4px;

    border: 1px solid $accent-color;

    transition: $transition;
    transition-timing-function: $cubic-bezier;
    margin-inline: auto;

    &:hover {
      background-color: transparent;
      color: $accent-color;
      border: 1px solid $accent-color;
    }

    @media (min-width: 1160px) {
      width: 220px;
      height: 41px;
      font-weight: 700;
      font-size: 16px;
      border-radius: 8px;
    }
  }
  // ! SWIPER

  & .swiper {
    width: 100%;
    max-width: 375px;
    @media (min-width: 760px) {
      width: 400px;
    }
    @media (min-width: 1160px) {
      display: none !important;
    }
  }
  & .swiper-slide {
    // height: 628px;
    // width: 100%;
    // z-index: -100 !important;
  }

  & .swiper-button-prev,
  & .swiper-rtl & .swiper-button-next {
    position: relative;
    width: 19px !important;
    height: 30px !important;
    color: transparent !important;
    background: url('../images/triathlon/packages/left.png') center/contain no-repeat;

    @media (min-width: 760px) {
    }
    @media (min-width: 1160px) {
      display: none !important;
    }
  }

  & .swiper-button-next {
    position: relative;
    width: 19px !important;
    height: 30px !important;
    background: url('../images/triathlon/packages/right.png') center/contain no-repeat;
    color: transparent !important;
    @media (min-width: 1160px) {
      display: none !important;
    }
  }
}

.arrows {
  display: flex;
  position: absolute;
  left: 50%;
  top: 10px;
  gap: 35px;
  transform: translate(-50%, 34px);
}
