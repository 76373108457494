@import '~node_modules/modern-normalize/modern-normalize.css';

// .iti__flag {
//   background-image: url('path/to/flags.png');
// }

// @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
//   .iti__flag {
//     background-image: url('path/to/flags@2x.png');
//   }
// }

@function vh($quantity) {
  @return calc(var(--vh, 1vh) * #{$quantity});
}

html {
  scroll-behavior: smooth;
  // scroll-padding-top: 120px;
}

.hidden-html {
  overflow: hidden;
}

body {
  background-color: $primary-bg-color;
  // background-color: wheat;
  color: $light-text-color;
  font-family: $fontFamily;
  // overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  // // overscroll-behavior-y: none;
  // overflow: hidden;
}

// body.lock {
//   // overflow: hidden;
//   position: fixed;
// }

html.is-locked body {
  /* want to fix the height to the window height */
  height: calc(#{vh(100)} - 1px);

  /* want to block all overflowing content */
  overflow: hidden;

  /* want to exclude padding from the height */
  box-sizing: border-box;
}
// section {
//   padding: 25px 0;
// }

.wrapper {
  min-height: calc(#{vh(100)});
  overflow: hidden;
  // margin-top: 54px;
  // min-height: calc(#{vh(100)} - 54px);
  // display: flex;
  // flex-direction: column;
  // @media (min-width: 768px) {
  //   margin-top: 78px;
  //   min-height: calc(#{vh(100)} - 78px);
  // }
  // @media (min-width: 992px) {
  //   margin-top: 100px;
  //   min-height: calc(#{vh(100)} - 100px);
  // }
}

.container {
  margin: 0 auto;
  padding-inline: 20px;

  @media (min-width: 480px) {
    padding-inline: 30px;
  }
  @media (min-width: 768px) {
    max-width: 960px;
    padding-inline: 30px;
  }
  @media (min-width: 992px) {
    max-width: 1010px;
  }
  @media (min-width: 1180px) {
    max-width: 1220px;
  }
}

.block {
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }
  @media (min-width: 992px) {
    padding-block: 60px;
  }
}

//color uni selectors

[class*='_light'] {
  color: $light-text-color;
}

[class*='_dark'] {
  color: $dark-text-color;
}

[class*='_accent'] {
  color: $accent-color;
}

[class*='_red'] {
  color: $red-color;
}

.fullheight {
  height: vh(100);
}

//===BUTTONS

.button {
  display: inline-block;
  padding: 9px 13px;
  font-weight: 700;
  border-radius: 4px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: $transition;
  transition-timing-function: $cubic-bezier;
  &_accent {
    background-color: $accent-color;
    padding-top: 8px;
    color: $dark-text-color;
    border: 1px solid $accent-color;
    &:hover {
      color: $accent-color;
      background-color: transparent;
      cursor: pointer;
    }
  }

  &_accent-border {
    background-color: transparent;
    color: $accent-color;
    border: 1px solid $accent-color;
  }

  &__span {
    display: none;
  }

  @media (min-width: 760px) {
    font-weight: 700;
    font-size: 16px;
    padding-inline: 26px;
    height: 41px;
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    &__span {
      display: inline;
    }
  }

  &_square {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 0;
  }
}

.link {
  font-weight: 700;
  font-size: 12px;
  @media (min-width: 760px) {
    font-size: 16px;
  }
}

.icon-button {
  &:hover {
    cursor: pointer;
  }
}

//underlined part
.underlined {
  position: relative;
  width: fit-content;
  transition: $transition;
  transition-timing-function: $cubic-bezier;
  &:after {
    content: '';
    background-color: $accent-color;
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -7px;
    left: 0;
  }
  &:hover {
    // transform: scale(1.1);
    opacity: 0.7;
  }
}

.arrow-container {
  z-index: 10;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(12, 12, 12, 0.5); /* Полупрозрачный цвет #89d7d7 */
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.arrow-up {
  display: block;
  width: 20px;
  height: 20px;
  background: url('../images/triathlon/packages/left.png') center/contain no-repeat;
  transform: rotate(90deg);
}

//TEXT

.text {
  &__title {
    font-weight: 900;
    font-size: 19px;
    line-height: 120%;
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 30px;
    @media (min-width: 768px) {
      font-size: 40px;
    }
    @media (min-width: 992px) {
      margin-bottom: 60px;
    }
  }

  &__subtitle {
    font-size: 15px;
    font-weight: 600;
    line-height: 140%;
    @media (min-width: 768px) {
      font-size: 24px;
    }
  }

  &__body {
    font-weight: 500;
    font-size: 12px;
    line-height: 15.6px;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 20.8px;
    }
  }
}

.bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  > img {
    display: none;
  }
}

// Cursor

p,
h1,
h2,
li,
div {
  cursor: default;
}

//ICONS

.icon-container {
}

.icon {
}

.parallax {
  background-attachment: fixed;
}

.full-height {
  height: calc(#{vh(100)});
  // @media (min-width: 768px) {
  //   height: calc(#{vh(100)} - 78px);
  // }
  // @media (min-width: 992px) {
  //   height: calc(#{vh(100)} - 100px);
  // }
}
