.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: $primary-bg-color;
  transition: top 0.3s;

  &__body {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 54px;
    align-items: center;
    margin-inline: auto;

    @media (min-width: 768px) {
      height: 78px;
    }

    @media (min-width: 992px) {
      height: 100px;
    }
  }
  &__logo-wrapper {
    display: flex;
    align-items: center;

    @media (min-width: 992px) {
      gap: 45px;
    }
  }

  .header__logo {
    height: 26px;
    overflow: hidden;
    position: relative;

    @media (min-width: 768px) {
      height: 40px;
    }
    @media (min-width: 992px) {
      height: 57px;
    }
  }

  .header__logo img {
    height: 100%;
    display: block;
  }

  &__logo-text {
    display: none;

    @media (min-width: 992px) {
      display: block;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: $accent-color;
      user-select: none;
      max-width: 175px;
      position: relative;

      &:before {
        content: '';
        background-color: $accent-color;
        position: absolute;
        width: 2px;
        height: 39px;
        margin-right: -22px;
        top: 50%;
        left: -22px;
        transform: translateY(-50%);
      }
    }
  }

  &__flag {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 16px;
    // z-index: 50;

    @media (min-width: 768px) {
      width: 130px;
      height: 30px;
    }

    @media (min-width: 992px) {
      width: 190px;
      height: 42px;
    }
  }

  &__blue {
    display: inline-block;
    background: linear-gradient(90deg, rgba(27, 61, 241, 0) 8.85%, #1b3df1 42.71%, #1b3df1 59.37%, rgba(27, 61, 241, 0) 91.67%);
    width: 100%;
    flex: 1;
  }
  &__yellow {
    display: inline-block;
    background: linear-gradient(90deg, rgba(255, 230, 0, 0) 8.85%, #ffe600 42.71%, #ffe600 59.37%, rgba(255, 230, 0, 0) 91.67%);
    width: 100%;
    flex: 1;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 20px;
    @media (min-width: 768px) {
      gap: 40px;
    }
  }

  &__cta.button {
    display: none;
    @media (min-width: 992px) {
      display: inline-flex;
    }
  }

  &__lang-btn {
    width: 28px;
    height: 28px;
    appearance: none;
    font-weight: 700;
    font-size: 10px;
    line-height: 120%;
    color: $accent-color;
    border: 1.7px solid $accent-color;
    border-radius: 3px;
    background-color: transparent;
    text-align: center;
    cursor: pointer;
    z-index: 20;

    transition: $transition;
    transition-timing-function: $cubic-bezier;
    &:hover {
      background-color: $accent-color;
      color: $dark-text-color;
    }
    &:focus-visible {
      outline: none;
    }

    @media (min-width: 768px) {
      width: 31px;
      height: 31px;
      font-size: 12px;
      border-radius: 5px;
    }

    @media (min-width: 992px) {
      width: 41px;
      height: 41px;
      font-size: 16px;
      border-radius: 7px;
    }
  }
  &__lang-opt {
    width: 28px;
    height: 28px;
    background-color: $accent-color;
    color: $dark-text-color;
    font-weight: 700;
    font-size: 10px;
    line-height: 120%;
    text-decoration: none;
    text-align: center;
    border: 1.7px solid $accent-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: $transition;
    transition-timing-function: $cubic-bezier;

    &:hover {
      background-color: $dark-text-color;
      color: $accent-color;
    }

    &:nth-child(1) {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:nth-child(3) {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    @media (min-width: 768px) {
      width: 31px;
      height: 31px;
      font-size: 12px;
      &:nth-child(1) {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }
      &:nth-child(3) {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }

    @media (min-width: 992px) {
      width: 41px;
      height: 41px;
      font-size: 16px;
    }
  }
}

// .header__body {
//   position: relative;
//   display: flex;
//   justify-content: space-between;
//   height: 54px;
//   align-items: center;
//   padding-inline: 20px;
//   margin-inline: auto;
//   @media (min-width: 768px) {
//     max-width: 1400px;
//     height: 100px;
//   }
// }

.header__burger {
  display: block;
  position: relative;
  width: 20px;
  height: 18px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  span {
    position: absolute;
    background-color: $accent-color;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 8px;
    top: 8px;
    transition: all 0.3s ease 0s;
  }
  &:before {
    content: '';
    background-color: $accent-color;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    border-radius: 8px;
    transition: all 0.3s ease 0s;
    top: 0;
  }
  &:after {
    content: '';
    background-color: $accent-color;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    border-radius: 8px;
    transition: all 0.3s ease 0s;
    bottom: 0;
  }

  @media (min-width: 768px) {
    width: 28px;
    height: 24px;
    span {
      top: 10px;
    }
    span,
    &:after,
    &::before {
      height: 4px;
    }
  }
}
// .header__burger.active {
//   span {
//     transform: scale(0);
//   }
//   &:before {
//     transform: rotate(45deg);
//     top: 8px;
//   }
//   &:after {
//     transform: rotate(-45deg);
//     bottom: 8px;
//   }

//   @media (min-width: 768px) {
//     &:before {
//       top: 10px;
//     }
//     &:after {
//       bottom: 10px;
//     }
//   }
// }

.header__menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  overflow: auto;
  transition: all 0.3s ease 0s;
  background-color: $dark-text-color;
  padding: 100px 10px 20px 10px;

  @media (min-width: 768px) {
    width: 200px;
  }
  @media (min-width: 768px) {
    width: 350px;
    padding: 120px 10px 20px 10px;
  }
}

.header__menu.active {
  right: 0;
}

.header__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    align-items: flex-start;
  }
}
.header__list li {
  margin: 0px 0px 20px 0px;
  transition: $transition;
  transition-timing-function: $cubic-bezier;
  &:hover {
    opacity: 0.7;
    // transform: scale(1.1);
  }
}
.header__link,
.footer__link {
  color: $accent-color;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  gap: 17px;
  z-index: 30;
  &_line {
    @media (min-width: 768px) {
      &:after {
        content: '';
        background-color: $accent-color;
        position: absolute;
        width: 64px;
        height: 2px;
        bottom: 110px;
        right: 143px;
      }
    }
  }
}

.icons {
  transition: $transition;
  transition-timing-function: $cubic-bezier;
  // width: 30px;
  height: 24px;

  @media (min-width: 768px) {
    cursor: pointer;
    // width: 35px;
    height: 22px;
  }

  &:hover {
    opacity: 0.7;
    // transform: scale(1.1);
  }
}

.contacts_email {
  color: $accent-color;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  top: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.show {
  display: block;
}

.header-hide {
  top: -100px;
}
