.footer {
  position: relative;
  padding-bottom: 40px;
  color: $accent-color;
  height: 100%;
  @media (min-width: 768px) {
    padding: 67px 0;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 6px;
    background-color: $accent-color;
    top: 0;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__logo {
    margin: 40px 0;
    @media (min-width: 768px) {
      margin: 0;
    }
  }

  &__logo-img {
    width: 62px;
    @media (min-width: 768px) {
      width: 99px;
    }
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    padding-bottom: 35px;

    font-weight: 700;
    font-size: 12px;
    line-height: 120%;

    &::after {
      content: '';
      position: absolute;
      z-index: 10;
      width: 105px;
      height: 1px;
      background-color: $accent-color;
      bottom: 0;
    }

    @media (min-width: 768px) {
      padding: 0;

      &::after {
        content: '';

        display: none;
      }
    }

    @media (min-width: 1100px) {
      flex-direction: row;
      align-items: center;
      font-size: 16px;
    }
  }

  &__item {
    transition: $transition;
    transition-timing-function: $cubic-bezier;
    &:hover {
      // transform: scale(1.1);
      opacity: 0.7;
    }
  }

  &__contacts {
    margin-top: 20px;
    @media (min-width: 768px) {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        z-index: 10;
        width: 1px;

        height: 259px;
        background-color: $accent-color;
        left: -35px;

        top: -90px;
        @media (min-width: 1100px) {
          height: 205px;
          top: -65px;
        }
      }
    }
  }

  &__social-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 22px;
    gap: 17px;
    &::after {
      content: none;
    }
    @media (min-width: 768px) {
      justify-content: flex-start;
    }
  }

  &__icon-link {
    cursor: pointer;
    transition: $transition;
    transition-timing-function: $cubic-bezier;
    &:hover {
      // transform: scale(1.1);
      opacity: 0.7;
    }
  }

  &__icons {
    // width: 20px;
    height: 20px;
    color: $accent-color;

    @media (min-width: 768px) {
      // width: 23px;
      height: 23px;
    }
  }

  &__contacts_email {
    font-weight: 700;
    font-size: 12px;
    line-height: 120%;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
}
