.tri {
  position: relative;

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url('../images/triathlon/hero/tri-mobile-hero.webp') center/cover no-repeat;
  // background-attachment: fixed;

  // #myVideo {
  //   display: none;
  // }
  // @media (min-width: 768px) {
  //   // margin-top: 100px;
  //   #myVideo {
  //     position: fixed;
  //     right: 50%;
  //     transform: translateX(50%);
  //     bottom: 0;
  //     min-width: 100%;
  //     min-height: 100%;
  //     display: block;
  //     background-attachment: fixed;
  //   }
  // }
  &_blur {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1.5px);
    @media (min-width: 768px) {
      backdrop-filter: blur(4px);
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)) center/cover no-repeat;
    }
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
  }

  &__title {
    font-size: 27px;
    font-weight: 400;
    line-height: 130%;
    color: $accent-color;
    text-transform: uppercase;
    @media (min-width: 480px) {
      font-size: 37px;
    }
    @media (min-width: 768px) {
      font-size: 52px;
    }
    @media (min-width: 992px) {
      font-size: 64px;
    }
  }

  &__title-italic {
    font-style: italic;
    font-weight: 800;
  }
  &__title-thin {
    font-style: unset;
    font-weight: 200;
  }

  &__subtitle {
    display: none;
    @media (min-width: 768px) {
      display: block;
      font-weight: 600;
      font-size: 30px;
      line-height: 140%;
      color: $accent-color;
    }
  }

  &__text {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    color: $accent-color;
    margin-top: 20px;
    @media (min-width: 768px) {
      display: none;
    }
  }

  &__btn {
    margin-top: 70px;
    border: none;
    width: 164.5px;
    height: 32px;

    font-weight: 700;
    font-size: 12px;
    line-height: 120%;
    color: $primary-bg-color;
    background-color: $accent-color;
    border: 1px solid $accent-color;
    border-radius: 4px;

    transition: $transition;
    transition-timing-function: $cubic-bezier;
    &:hover {
      cursor: pointer;
      background-color: transparent;
      color: $accent-color;
      border: 1px solid $accent-color;
    }
    @media (min-width: 768px) {
      font-size: 16px;
      width: 233px;
      height: 41px;
      border-radius: 8px;
    }
  }
  &__pin {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: inline-block;
    height: 33px;
    width: 11px;

    background: url('../images/triathlon/hero/pin.png') center/cover no-repeat;
  }
}

section.tri {
  padding: 0;
}
