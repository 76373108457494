.achievements {
  height: 100%;
  background: url('../images/triathlon/achievements/background.png') center/contain;
  background-color: #000;
  position: relative;

  // .achievements__title
  &__title {
    margin-bottom: 25px;
    @media (min-width: 992px) {
      margin-bottom: 60px;
    }
  }
}

.results {
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
  // .results__result-item

  &__result-item {
    aspect-ratio: 1/1;
    min-width: 320px;
    width: 100%;
    max-width: 420px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.47) 40.52%, rgba(0, 0, 0, 0.78) 60.54%, rgba(0, 0, 0, 0.47) 90.89%);
    border: 1px solid;
    border-image-source: linear-gradient(#89d7d7, rgba(137, 215, 215, 0.25), rgba(137, 215, 215, 0.26), #89d7d7);
    border-image-slice: 1;
    border-width: 1px;

    &:nth-child(-n + 2),
    &:nth-child(5) {
      & p {
        max-width: 210px;
      }
    }
  }
  &__records-block {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  // .results__records

  &__records {
    aspect-ratio: 16/9;
    min-width: 320px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0.78));
    border: 1px solid;
    border-image-source: linear-gradient(#89d7d7, rgba(137, 215, 215, 0.25), rgba(137, 215, 215, 0.26), #89d7d7);
    border-image-slice: 1;
    border-width: 1px;
    min-width: 320px;
    width: 100%;
    max-width: 420px;
  }

  @media (min-width: 705px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__result-item {
      max-width: none;
    }
    &__records {
      width: 100%;
      aspect-ratio: auto;
      flex: 1;
      max-width: none;
    }
    &__records-block {
      height: 100%;
    }
    &:nth-child(-n + 2),
    &:nth-child(5) {
      & p {
        max-width: none;
      }
    }
  }

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: 1fr 1fr 1fr;
    gap: 15px;
    &__result-item {
      padding: 15px;
      border: 2px solid;
      border-image-source: linear-gradient(#89d7d7, rgba(137, 215, 215, 0.25), rgba(137, 215, 215, 0.26), #89d7d7);
      border-image-slice: 2;
      border-width: 2px;
    }

    &__result-item:nth-child(1) {
      grid-area: 1 / 1 / 3 / 3;
      .info__numbers-big {
        font-size: 120px;
        font-weight: 800;
        line-height: 144px;
      }

      .info__text {
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        top: calc(50% + 60px);
        max-width: 270px;
      }
    }
    &__result-item {
      min-width: auto;
    }

    &__records-block {
      flex-direction: row;
      grid-area: 3/1/3/5;
      gap: 15px;
    }
  }

  @media (min-width: 1180px) {
    gap: 30px;
    &__records-block {
      gap: 30px;
    }
  }
}

.info {
  // .info__numbers-big
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__numbers-big {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-weight: 800;
    font-size: 50px;
    line-height: 120%;
    color: rgba(137, 215, 215, 0.8);
  }

  // .info__text

  &__text {
    font-size: 15px;
    font-weight: 600;
    line-height: 140%;
    text-align: center;
    position: absolute;
    padding-inline: 10px;
    top: calc(50% + 35px);
    max-width: 300px;
  }

  @media (min-width: 705px) {
    &__text {
      top: calc(50% + 25px);
    }
  }

  @media (min-width: 992px) {
    &__text {
      font-weight: 500;
      max-width: auto;
      // margin: 10px;
      top: calc(50% + 35px);
    }
  }
}

.records {
  // .records__content
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px;

  &__content_run_block {
    outline: 1px solid $accent-color;
    // padding: 10px 15px 5px 15px;

    @media (min-width: 992px) {
      min-width: 115px;
      // padding: 10px 25px 5px 25px;
    }
    @media (min-width: 1180px) {
      // padding: 10px 25px 5px 25px;
    }
  }
  &__run_distance {
    // min-width: 100px;
    padding-block: 15px;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: $light-text-color;
    text-align: center;

    white-space: nowrap;
    background: rgba(0, 0, 0, 0.549);

    @media (min-width: 705px) {
      font-size: 16px;
      line-height: 120%;
      color: $light-text-color;
    }
    @media (min-width: 992px) {
      min-width: 115px;
      // padding: 10px 25px 5px 25px;
    }
  }
  &:last-child {
    background-position: top;
  }

  &__content {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    // .records__content_text

    &_text {
      flex: 1;
      //styleName: mobile/title_mobile;
      font-family: Montserrat;
      font-size: 19px;
      font-weight: 700;
      letter-spacing: 0em;
      text-align: left;
      line-height: 140%;
    }

    // .records__content_block

    &_block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      gap: 13px;
    }

    @media (min-width: 705px) {
      &_block {
        flex: 1;
        height: 100%;
      }
    }

    @media (min-width: 992px) {
      &_text {
        font-weight: 700;
        font-size: 30px;
        line-height: 105.5%;
      }
    }

    @media (min-width: 1180px) {
      &_text {
        font-weight: 700;
        font-size: 40px;
        line-height: 105.5%;
      }
    }
  }

  &__run-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 18px;
    @media (min-width: 992px) {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      gap: 12px;
    }
  }
  // .records__iron-icon

  &__iron-icon {
    width: 60%;
    align-self: center;
  }
  @media (min-width: 992px) {
    padding: 25px;
  }
  // .records__details
}

.details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  //styleName: mobile/sub_title_mobile;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;

  @media (min-width: 992px) {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    gap: 20px;
  }
}

.run-details {
  gap: 10px;
  margin-block: 5px;
  @media (min-width: 992px) {
    gap: 15px;
    margin-block: 5px;
  }
}
