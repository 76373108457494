.succeed {
  background-color: $accent-color;
  padding: 0;
  overflow: hidden;
  position: relative;
  &__title {
    position: relative;
    z-index: 2;
  }

  &__info-bg-flower-desc-1 {
    display: none;

    @media (min-width: 992px) {
      display: block;
      position: absolute;
      right: -30px;
      bottom: 70%;

      width: 120px;
      height: 120px;
    }
  }

  &__info-bg-flower-desc-2 {
    display: none;
    @media (min-width: 992px) {
      display: block;
      position: absolute;
      right: 15%;
      bottom: 40%;

      width: 150px;
      height: 150px;
    }

    @media (min-width: 990px) {
      display: block;
      position: absolute;
      right: 25%;
      bottom: 25%;

      width: 150px;
      height: 150px;
    }
  }

  &__info-bg-flower-desc-3 {
    display: none;
    @media (min-width: 992px) {
      display: block;
      position: absolute;
      right: -5%;
      bottom: 10px;

      width: 300px;
      height: 300px;
    }
    @media (min-width: 990px) {
      display: block;
      position: absolute;
      right: -3%;
      bottom: 10px;

      width: 300px;
      height: 300px;
    }
  }

  &__container {
    padding: 0;
    display: flex;

    @media (min-width: 992px) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 30px;
    }
  }

  &__icons {
    // display: flex;
    // flex-direction: column;
    flex: 1;
    @media (min-width: 768px) {
      grid-column: 1 / -7;
    }
  }

  &__swiper {
    width: 100%;
    grid-column: 1 / span 4;
    max-width: 500px;
    @media (min-width: 768px) {
      grid-column: 8 / span 5;
      padding-left: 10px;
    }
    @media (min-width: 1280px) {
      padding-left: 30px;
    }
  }

  &__bg {
    position: relative;
    background: linear-gradient(rgba(137, 215, 215, 0.7), rgba(137, 215, 215, 0.7)), url('../images/triathlon/succeed/bg.jpg') center/cover no-repeat;
  }

  &__list {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-rows: repeat(3, 1fr); /* 3 рядки з однаковою висотою */
    grid-template-columns: repeat(2, 1fr); /* 2 колонки з однаковою шириною */
    grid-row-gap: 15px; /* проміжок між рядками */
    grid-column-gap: 10px; /* проміжок між колонками */
    margin-top: 25px;
    align-self: center;
    justify-items: center;

    @media (min-width: 768px) {
      display: grid;
      grid-template-rows: repeat(3, 1fr); /* 3 рядки з однаковою висотою */
      grid-template-columns: repeat(2, 1fr); /* 2 колонки з однаковою шириною */
      grid-row-gap: 20px; /* проміжок між рядками */
      grid-column-gap: 20px; /* проміжок між колонками */

      margin-top: 50px;
      padding: 0;
    }
  }

  &__item {
    height: 100%;
    text-align: center;
    border: 2px solid transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 200px;
    padding-block: 10px;
    border-radius: 10px;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
    @media (min-width: 650px) {
      width: 300px;
    }
    @media (min-width: 768px) {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-width: 300px;
      padding: 10px;
      border-radius: 10px;

      /*BACKGROUND STYLE*/

      background: linear-gradient(#fff0, #fff0 50%, #ffffff46 50%, #ffffff46) 0 -3px / 100% 210%;
      /*trasition effect for background*/
      transition: background 1s;
      &:hover {
        background-position: 100% 100%;
        cursor: pointer;
      }
    }
    @media (min-width: 992px) {
      width: 100%;
    }
    @media (min-width: 1180px) {
      width: 300px;
    }

    &.active {
      background: linear-gradient(#fff0, #fff0 50%, #ffffff46 50%, #ffffff46) 0 -3px / 100% 210%;
      transition: background 1s;
      background-position: 100% 100%;
    }
  }

  &__text {
    color: $dark-text-color;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    @media (min-width: 768px) {
      font-size: 16px;
      max-width: 220px;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__icon {
    width: 32px;
    @media (min-width: 768px) {
      width: 62px;
      margin-bottom: 12px;
    }
  }

  // &__wrap-info {
  //   position: relative;
  //   padding-bottom: 50px;
  //   @media (min-width: 768px) {
  //     width: 100%;
  //     min-height: 100%;
  //     overflow: hidden;

  //     // display: flex;
  //     // align-items: center;
  //     padding: 0 40px;
  //   }

  //   // ! SWIPER
  // }

  &__triangle {
    position: absolute;
    top: -30;
    left: 20;
    width: 77px;
    @media (min-width: 992px) {
      display: none;
    }
  }

  &__triangle-left {
    display: block;
    position: absolute;
    transform: rotate(90deg) translateX(32px);
    bottom: 0;
    left: 15%;

    width: 44px;

    z-index: 3;

    @media (min-width: 768px) {
      width: 51px;
    }
    @media (min-width: 992px) {
      display: block;
      position: absolute;
      top: 137px;
      height: 119px;
      transform: translateX(-70%);
      // transform: translateY(0%);
      left: 57%;
      width: 77px;
    }
    @media (min-width: 1760px) {
      // display: block;
      // position: absolute;
      // top: 137px;
      // height: 119px;
      // transform: translateX(-70%);
      // transform: translateY(0%);
      left: 54%;
      // width: 77px;
    }
  }

  &__info-text {
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    color: $dark-text-color;
    padding: 25px 0;
    width: 90%;
  }

  &__info-bg-flower-mob {
    position: absolute;
    right: -10;
    bottom: 30px;
    width: 85px;
    aspect-ratio: 1/1;
    @media (min-width: 768px) {
      width: 20%;
    }
    @media (min-width: 992px) {
      display: none;
    }
  }

  &__full-width {
    width: 100%;
    display: contents;
  }
}

.mySwiper {
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    top: 30px;
  }

  @media (min-width: 992px) {
    top: 0;
    display: flex;
    flex-direction: column;
  }

  .swiper {
    overflow: hidden;
    height: 100%;
  }
  &__arrows {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 34px;
    justify-content: flex-end;

    & .swiper-button-prev,
    & .swiper-button-next {
      @media (max-width: 992px) {
        display: none !important;
      }
      @media (min-width: 992px) {
        position: relative !important;
        top: 0px !important;
        // left: 60% !important;
        width: 39px !important;
        height: 30px !important;
        color: transparent !important;
        background: url('../images/triathlon/succeed/arrow-left.png') center/contain no-repeat;
        transform: translateY(100%);
        &:hover {
          opacity: 0.6;
        }
      }
    }

    & .swiper-button-next {
      @media (max-width: 992px) {
        display: none !important;
      }
      @media (min-width: 992px) {
        position: relative !important;
        top: 0px !important;
        right: 0px !important;
        // left: 80% !important;
        width: 39px !important;
        height: 30px !important;
        color: transparent !important;
        background: url('../images/triathlon/succeed/arrow-right.png') center/contain no-repeat;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  &__wrapper {
    @media (min-width: 992px) {
      margin-top: 95px;
      width: 376px;
    }

    & .swiper-slide {
      width: 100%;
      color: #000;
      font-size: 15px;
      line-height: 21px;
      width: 300px;

      @media (min-width: 768px) {
        font-size: 21px;
        line-height: 130%;
        color: #000;
        font-weight: 600;
        width: 376px;
      }
      @media (min-width: 1180px) {
        font-size: 24px;
        color: #000;
        line-height: 31px;
        font-weight: 600;
        width: 376px;
        padding-right: 25px;
      }
    }
  }
  // & .swiper-slide {
  //   //   * Center slide text vertically *
  //   display: -webkit-box;
  //   display: -ms-flexbox;
  //   display: -webkit-flex;
  //   display: flex;
  //   -webkit-box-pack: center;
  //   -ms-flex-pack: center;
  //   -webkit-justify-content: center;
  //   justify-content: center;
  //   -webkit-box-align: center;
  //   -ms-flex-align: center;
  //   -webkit-align-items: center;
  //   align-items: center;
  //   text-align: start;
  // }
}

.mySwiper2 {
  & .swiper-pagination {
    position: relative;
    padding-top: 40px !important;
    // bottom: 40px !important;

    // @media (min-width: 760px) {
    //   bottom: 65% !important;
    // }

    @media (min-width: 1200px) {
      display: none;
    }

    & .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      line-height: 20px;
      font-size: 12px;
      color: #000;
      background-color: transparent;
      border: 1px solid rgba(0, 0, 0, 1);
      opacity: 1;
      @media (min-width: 1200px) {
        display: none;
      }
    }

    & .swiper-pagination-bullet-active {
      color: #fff;
      background: black;
      @media (min-width: 1200px) {
        display: none;
      }
    }
  }
}

._gradient-bg-transparent {
  position: absolute;
  top: 0%;
  left: 0%;
  background-image: linear-gradient(180deg, rgba(137, 215, 215, 0.7) 0%, rgba(137, 215, 215, 0.7) 100%);
  background-position: right;
  height: 100%;
  width: 100%;

  @media (min-width: 992px) {
    width: 57%;
  }
  @media (min-width: 1760px) {
    width: 54%;
  }
}

.succeed2 {
  display: grid;
  background-color: $accent-color;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;

  & .swiper-pagination {
    position: absolute;
    bottom: 40px !important;

    // @media (min-width: 760px) {
    //   bottom: 65% !important;
    // }

    @media (min-width: 1200px) {
      display: none;
    }

    & .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      line-height: 20px;
      font-size: 12px;
      color: #000;
      background-color: transparent;
      border: 1px solid rgba(0, 0, 0, 1);
      opacity: 1;
      @media (min-width: 1200px) {
        display: none;
      }
    }

    & .swiper-pagination-bullet-active {
      color: #fff;
      background: black;
      @media (min-width: 1200px) {
        display: none;
      }
    }
  }
}
.desktop {
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
}
.mobile {
  display: flex;
  justify-content: center;
  @media (min-width: 992px) {
    display: none;
  }
}

@media (max-width: 992px) {
  .mob-rel {
    position: relative;
  }
}
