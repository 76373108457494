.hero {
  width: 100%;
  // height: 100%;
  flex: 1;
  display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  max-height: 100%;
  margin-top: 54px;
  height: calc(#{vh(100)} - 54px);
  @media (min-width: 768px) {
    height: calc(#{vh(100)} - 78px);
    margin-top: 78px;
  }
  @media (min-width: 992px) {
    height: calc(#{vh(100)} - 100px);
    margin-top: 100px;
  }
  // .hero__tri
  &_thumb-wrapper {
    display: block;

    flex: auto;
    // flex-basis: 50%;

    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    &:hover {
      // background-size: 100%;
      cursor: pointer;
      transition-timing-function: $cubic-bezier;

      // animation: zoomin 0.7s;
      // animation-fill-mode: forwards;
    }
  }

  &__choose {
    position: absolute;
    top: 50%;
  }

  &__title-tri,
  &__title-run {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);

    font-style: italic;
    font-weight: 800;
    font-size: 27px;
    line-height: 120%;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
    }
  }

  &__title-tri {
    @media (min-width: 768px) {
      font-weight: 800;
      font-size: 42px;
      line-height: 120%;
    }
    @media (min-width: 1180px) {
      font-size: 64px;
    }
  }

  &__title-run {
    @media (min-width: 768px) {
      font-weight: 800;
      font-size: 42px;
      line-height: 120%;
    }
    @media (min-width: 1180px) {
      top: 30%;
      font-size: 64px;
    }
  }

  @media screen and (orientation: portrait) {
    &_thumb-wrapper {
      width: 100vw;
    }
    &_bg-top {
      background-position: center top 30%;
    }
    flex-direction: column;
  }

  @media screen and (orientation: landscape) {
    flex-direction: row;

    &_thumb-wrapper {
      width: 50vw;
    }

    &__title-tri {
      top: 60%;
      left: 60%;
    }
    &__title-run {
      top: 33%;
      right: -10%;
    }
  }

  @media (min-width: 769px) {
    &_thumb-wrapper {
      &:hover {
        // background-size: 100%;
        cursor: pointer;
        transition-timing-function: $cubic-bezier;
        z-index: 1;
        animation: zoomin 0.7s;
        animation-fill-mode: forwards;
      }
    }
  }
}

.choose {
  // .choose__arrow-up
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 3;
  &__arrow-up {
    width: 20px;
    height: 60px;
    background: url('../images/hero/arrow-up.svg') no-repeat center;
  }

  // .choose__text

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    white-space: nowrap;
    @media (min-width: 768px) {
      // top: 47%;
      font-size: 16px;
    }
  }

  // .choose__arrow-down

  &__arrow-down {
    width: 20px;
    height: 60px;
    background: url('../images/hero/arrow-down.svg') no-repeat center;
  }

  @media screen and (orientation: landscape) {
    &__arrow-down {
      width: 60px;
      height: 60px;
      transform: translateX(-100%);
      background: url('../images/hero/arrow-left.svg') no-repeat center;
    }
    &__arrow-up {
      width: 60px;
      height: 60px;
      transform: translateX(100%);
      background: url('../images/hero/arrow-right.svg') no-repeat center;
    }
  }
}

@-webkit-keyframes zoomin {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

// .run-link {}
