.contact-form {
  position: relative;
  gap: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: 300px;
  background: $dark-text-color;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 13px;
  // color: $light-text-color;
  color: black;
  padding: 30px 35px;

  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 2px;
    background: linear-gradient(180deg, #2b4aed, #ffe600);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  @media (min-width: 1180px) {
    gap: 22px;
    width: 416px;
    //height: 362px;
    border-radius: 23px;
    border-width: 3px;
    padding: 35px 30px;
    &::after {
      border-radius: 18px;
      padding: 3px;
    }
  }
  &__title,
  &__subtitle,
  &__notice {
    color: $light-text-color;
  }
}

.form {
  font-size: 14px;
  color: $accent-color;
  &__input {
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    height: 32px;
    padding: 15px 10px;
    background-color: transparent;
    color: $accent-color;
    border: 1px solid $accent-color;
    border-radius: 8px;
    &::placeholder {
      font-size: 12px;
      color: $accent-color;
      font-weight: 500;
    }
    &:focus-visible {
      font-size: 14px;
      color: $accent-color;
      border-color: $accent-color;
      border-width: 2px;
      outline: none;
    }
    &:focus::placeholder {
      color: transparent;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    &:hover {
      cursor: pointer;
    }
  }

  @media (min-width: 1180px) {
    width: 100%;
    &__button {
      font-weight: 700;
      padding-inline: 26px;
      height: 41px;
      display: inline-flex;
      align-items: center;
      &__span {
        display: inline;
      }
    }

    &__input {
      font-weight: 500;
      height: 41px;
      font-size: 12px;
    }
  }
}

.iti__country-list {
  background-color: black !important;
  border: 1px solid $accent-color !important;
  width: 246px;
  white-space: normal !important;
  @media (min-width: 1180px) {
    width: 350px;
  }
}

.iti__selected-dial-code {
  font-size: 14px !important;
  font-weight: 400 !important;
  white-space: break-spaces !important;
}

.error-text {
  font-size: 10px;
  color: $accent-color;
  font-weight: 500;
  position: absolute;
  bottom: 11px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

#toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-weight: 300;
  // transform: translateX(-50%);
  padding: 12px 24px;
  border-radius: 4px;
  background-color: rgb(0, 0, 0);
  color: $accent-color;
  font-size: 16px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

#toast.show {
  opacity: 1;
}
