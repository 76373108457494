* {
  scrollbar-width: thin;
  scrollbar-color: $dark-text-color $accent-color;
}

*::-webkit-scrollbar {
  width: 0px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 3px solid $accent-color;
}
