.faq {
  @media (min-width: 760px) {
  }
  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 19px;
    line-height: 120%;
    color: $accent-color;
    text-transform: uppercase;

    @media (min-width: 760px) {
      font-size: 40px;
    }
  }

  &__list {
    display: grid;
    gap: 25px;
  }

  &__item {
    position: relative;
    height: 100%;
    background: #272727;
    border-radius: 8px;
    padding: 15px 18px;
    cursor: pointer;

    &:hover {
      background: #393939;
    }
    @media (min-width: 760px) {
      padding: 30px 69px;
    }
  }

  &__question {
    font-weight: 700;
    font-size: 14px;
    line-height: 125%;
    color: $accent-color;
    margin-bottom: 22px;
    width: 90%;
    @media (min-width: 760px) {
      font-size: 18px;
    }
    @media (min-width: 1200px) {
      width: 60%;
      font-weight: 600;
      font-size: 22px;
      line-height: 31.2px;
    }
  }

  &__btn {
    position: absolute;
    top: 20px;
    right: 18px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    background: url('../images/triathlon/faq/plus.png') no-repeat center/cover;
    width: 12px;
    height: 12px;
    transition: $transition;
    transition-timing-function: $cubic-bezier;
    &:hover {
      opacity: 0.7;
    }

    &.active {
      transform: rotate(0.5turn);
      background: url('../images/triathlon/faq/minus.png') no-repeat center/contain;
    }

    @media (min-width: 760px) {
      width: 30px;
      height: 30px;
      right: 25px;
    }

    @media (min-width: 1200px) {
      width: 22px;
      height: 22px;

      top: 35px;
      right: 70px;
    }
  }

  &__answer {
    max-height: 0;
    overflow: hidden;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    transition: max-height 0.2s ease-out;

    @media (min-width: 760px) {
      font-weight: 600;
      font-size: 18px;
      line-height: 135%;
    }

    @media (min-width: 1200px) {
      width: 60%;
      font-weight: 600;
      font-size: 16px;
      line-height: 135%;
    }
  }
}
