.programs {
  height: 100%;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url('../images/triathlon/programs/programs-bg.webp') center/cover no-repeat;
  // background-attachment: fixed;
  @media (min-width: 760px) {
    // background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../images/triathlon/programs/programs.gif') center/cover no-repeat;
    // background-attachment: fixed;
  }

  // backdrop-filter: blur(2px);
  // .programs__wrap

  &_blur {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1.5px);
    @media (min-width: 760px) {
      backdrop-filter: blur(4px);
      background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    }
  }
  &__wrap {
    // padding-bottom: 100px;
  }

  // .programs__title

  // &__title {
  //   padding-top: 25px;
  //   @media (min-width: 1200px) {
  //     padding-top: 40px;
  //   }
  // }

  // .programs__subtitle

  &__subtitle {
    margin-top: 5px;
    @media (min-width: 1200px) {
      margin-top: 40px;
    }
  }

  // .programs__cards

  &__cards {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;

    @media (min-width: 580px) {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      justify-items: center;
      gap: 30px;
    }

    @media (min-width: 1180px) {
      gap: 30px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
    }
  }

  // .programs__cta

  &__cta {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    @media (min-width: 760px) {
      flex-direction: row;
      justify-content: space-around;
    }

    @media (min-width: 1200px) {
      margin-top: 60px;
    }
  }

  // .programs__cta-text

  &__cta-text {
    text-align: center;
    max-width: 330px;
    @media (min-width: 760px) {
      max-width: 500px;
    }
  }

  // .programs__cta-button

  &__cta-button {
    width: 200px;
    @media (min-width: 760px) {
      width: 277px;
    }
  }
}

.cards {
}

.program-card {
  // .program-card__head
  width: 100%;
  max-width: 320px;
  @media (max-width: 480px) {
    max-width: 100%;
    width: 100%;
  }

  border: 1px solid $accent-color;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__head {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $accent-color;
    text-align: center;
    @media (min-width: 760px) {
      padding-block: 50px;
    }
  }

  // .program-card__body

  &__body {
    flex: 1;
    padding-bottom: 35px;
  }

  // .program-card__actions

  &__actions {
    margin-bottom: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
  }
  @media (min-width: 760px) {
    // width: 267px;
    width: 100%;
    max-width: 360px;
  }
}

.checked-list {
  // .checked-list_item
  margin-inline: 10%;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  gap: 15.6px;
  justify-content: center;

  // &_first {
  //   margin-top: 11%;
  // }
  &__item {
    display: flex;
    align-items: center;
    &::before {
      content: 'L';
      margin-right: 14px;
      font-family: arial;
      font-weight: 600;
      color: $accent-color;
      display: inline-flex;
      align-self: baseline;
      -ms-transform: scaleX(-1) rotate(-35deg); /* IE 9 */
      -webkit-transform: scaleX(-1) rotate(-35deg); /* Chrome, Safari, Opera */
      transform: scaleX(-1) rotate(-45deg);
    }
  }

  @media (min-width: 760px) {
    margin-inline: 19px 15px;
  }
}

section.programs {
  padding: 0;
}

.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-bg {
  display: none;
  @media (min-width: 768px) {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    display: block;
    background-attachment: fixed;
  }
}
