.packages {
  height: 100%;
  background: url('../images/triathlon/packages/background.png') center/contain;

  //  @media (min-width: 1180px) {
  //     }
  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 19px;
    line-height: 120%;
    color: $accent-color;

    @media (min-width: 760px) {
      font-size: 40px;
    }
  }

  &__list {
    display: grid;
    grid-auto-rows: 1fr;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0 auto;
    gap: 25px;

    @media (min-width: 720px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      gap: 25px;
      justify-items: center;
    }
    @media (min-width: 1180px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;
    }
  }

  &__item {
    position: relative;
    border: 1px solid $accent-color;
    width: 320px;
    height: 100%;
    padding: 27px;

    @media (max-width: 480px) {
      width: 100%;
    }
    background: rgba(0, 0, 0, 0.42);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    @media (min-width: 720px) {
      &:last-child {
        justify-self: end;
        transform: translateX(calc(50% + 13px)) !important;
      }
    }
    @media (min-width: 1110px) {
      border-radius: 17px;
    }
    @media (min-width: 1180px) {
      margin-bottom: 0;
      width: 367px;
      &:last-child {
        justify-self: inherit;
        transform: none !important;
      }
    }
  }

  &__icon-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    right: 30px;
  }

  &__icon {
    filter: drop-shadow(0px 2px 8px #89d7d7);
    width: 27px;
    height: 100%;
    @media (min-width: 760px) {
      width: 46px;
      height: 100%;
    }
  }

  &__icon-text {
    font-weight: 700;
    font-size: 8px;
    line-height: 120%;
    color: $accent-color;
    @media (min-width: 760px) {
      font-weight: 500;
      font-size: 12px;
      line-height: 123%;
    }
  }

  &__membership {
    font-weight: 700;
    font-size: 17px;
    line-height: 125%;
    color: $accent-color;
    padding-bottom: 2px;
    border-bottom: 2px solid $accent-color;
    width: fit-content;
    margin-bottom: 20px;

    @media (min-width: 1180px) {
      font-weight: 600;
      font-size: 30px;
      line-height: 140%;
    }
  }

  &__price-crossed-wrap {
    position: relative;
  }

  &__price-crossed {
    position: absolute;
    width: 70px;
    top: 5px;
    left: -7px;

    @media (min-width: 1180px) {
      width: 77px;
      left: -3px;
    }
  }

  &__old-price {
    font-weight: 600;
    font-size: 22px;
    line-height: 105.5%;
    margin-bottom: 10px;
    @media (min-width: 1180px) {
      font-size: 28px;
      line-height: 120%;
    }
  }

  &__price,
  &__price-run {
    font-weight: 800;
    font-size: 32px;
    line-height: 105.5%;
    color: $accent-color;

    @media (min-width: 1180px) {
      font-size: 40px;
      line-height: 120%;
    }
  }
  &__price {
    margin-bottom: 20px;
  }
  &__price-run {
    margin-bottom: 5px;
  }

  &__month {
    font-weight: 500;
    font-size: 28px;
    line-height: 120%;
    @media (min-width: 1180px) {
      font-size: 18px;
    }
  }
  &__year {
    color: $accent-color;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 22px;

    @media (min-width: 1180px) {
      font-size: 12px;
    }
  }

  &__features-list {
    list-style: disc;

    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    padding-left: 20px;
    padding-bottom: 25px;
    @media (min-width: 1180px) {
      font-size: 16px;
      padding-bottom: 35px;
    }
  }

  &__features-item:not(:last-child) {
    margin-bottom: 10px;
  }

  &__list-btn-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__btn-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  &__buy-btn,
  &__more-btn {
    font-weight: 700;
    font-size: 12px;
    // line-height: 120%;

    border: none;
    cursor: pointer;
    @media (min-width: 760px) {
      font-size: 16px;
    }
  }

  &__more-btn {
    background-color: transparent;
    color: $accent-color;
    padding-bottom: 2px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid $accent-color;
    width: fit-content;
    padding-bottom: 3px;
    margin-top: 10px;
    transition: $transition;
    transition-timing-function: $cubic-bezier;

    &:hover {
      // transform: scale(1.1);
      opacity: 0.7;
    }
  }
}
